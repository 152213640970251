<template>
  <base-layout>
    <div class="authResult">
      <div class="wait">
        <img :src="statusImg" alt="" />
        <span>{{ status ? "审核中" : "审核失败" }}</span>
      </div>
      <div class="result-tj">
        <span v-if="status">您已成功提交荷保保经纪人申请，我们会严格保密并为你申请执业证</span>
        <span v-else>驳回原因：{{ stateMsg }}</span>
      </div>
      <div class="result-phone" v-if="status">
        <span
          >近期会有工作人员与您联系办理，请保持手机畅通，如有疑问，请联系客服<a href="tel:4000761661"
            >4000761661</a
          ></span
        >
      </div>
      <div class="result-phone" v-else>如有疑问，请联系客服<a href="tel:4000761661">4000761661</a></div>
      <div class="backHome">
        <van-button type="primary" block color="#00B955" @click="onBackHome" v-if="status">返回首页</van-button>
        <van-button type="primary" block color="#00B955" @click="onRecertification" v-else>重新认证</van-button>
      </div>
    </div>
  </base-layout>
</template>

<script>
import { toMpHomePage } from "@/utils";
import { queryAuditState } from "@/api";

export default {
  components: {},
  data() {
    return {
      status: true,
      refereeId: null,
      stateMsg: "" // 驳回原因
    };
  },
  computed: {
    statusImg() {
      if (this.status) {
        return "https://files.htprudent.com/G3/M00/DB/34/CiICemBdb4WAb_1dAAAWll739NI907.png";
      } else {
        return "https://files.htprudent.com/G4/M00/DB/21/CiIDh2Bdb62AQw8bAAAXtFlRtUI914.png";
      }
    }
  },

  created() {
    document.title = "加入中";
  },
  mounted() {
    this.queryAuditState(); // 审核状态
  },

  methods: {
    // 申请状态
    async queryAuditState() {
      const { code, data = {} } = await queryAuditState();
      if (code === 200) {
        // 0 未审核(未认证) 1 审核中(认证中) 2 审核通过(已认证) 3 审核驳回(认证驳回) 4 注销
        if (data.auditState * 1 === 0 || data.auditState * 1 === 1) {
          this.status = true;
        }
        if (data.auditState * 1 === 3) {
          this.status = false;
        }
        this.stateMsg = data.rejectedReason;
        this.refereeId = data.refereeId;
      } else {
        this.status = true;
      }
    },
    onBackHome() {
      toMpHomePage();
    },
    onRecertification() {
      this.$router.push({
        path: "/add-panel",
        query: {
          status: false,
          refereeId: this.refereeId
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.authResult {
  height: 100%;
  height: 100vh;
  // background: #fff;
  background: #f5f7f8;
  flex-direction: column;
  .wait {
    text-align: center;
    padding-top: 60px;
    img {
      width: 66px;
      height: 66px;
    }
    span {
      display: block;
      font-family: PingFangSC-Medium;
      font-size: 20px;
      color: #fc7f10;
      margin-top: 9px;
    }
  }
  .result-tj {
    font-family: "PingFangSC-Regular";
    font-size: 15px;
    color: #333333;
    margin: 19px 37px 0;
    padding-bottom: 12px;
    line-height: 28px;
    border-bottom: 0.5px solid #e3e3e3;
  }
  .result-phone {
    font-family: "PingFangSC-Regular";
    font-size: 14px;
    color: #808080;
    margin: 12px 37px 0;
    a {
      color: #4f89d1;
    }
  }
  .backHome {
    margin: 30px 82px;
  }
}
</style>
