<template>
  <div class="filed-from">
    <div class="tagTitle" @click="onSubmit">证件信息</div>
    <van-form ref="filedFrom" @submit="onSubmit" alidate-first>
      <van-field
        v-model="state.idCardType"
        name="idCardType"
        label="证件类型"
        placeholder="证件类型"
        readonly
        required
      />
      <van-field
        v-model="state.idCard"
        name="idCard"
        label="证件号"
        maxlength="18"
        required
        placeholder="请输入证件号"
        @blur="idCardBlur"
        :rules="[
          { required: true, message: '请输入证件号' },
          { validator: validatorIdCard, message: '请输入正确身份证号' }
        ]"
      />
      <van-field
        v-model="state.memberName"
        name="memberName"
        label="姓名"
        maxlength="10"
        required
        placeholder="请输入姓名"
        :formatter="formatterSpace"
        :rules="[{ required: true, message: '请输入姓名' }]"
      />
      <van-field
        v-model="state.sex"
        required
        name="sex"
        label="性别"
        :rules="[{ required: true, message: '请选择性别' }]"
      >
        <template #input>
          <van-radio-group v-model="state.sex" direction="horizontal">
            <van-radio name="0" checked-color="#00B955">男</van-radio>
            <van-radio name="1" checked-color="#00B955">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <!-- <van-field
        v-model="state.birthday"
        readonly
        name="birthday"
        label="出生日期"
        required
        :rules="[{ required: true, message: '请选择出生日期' }]"
        placeholder="请选择出生日期"
      /> -->

      <van-field
        v-model="state.nation"
        readonly
        clickable
        required
        name="nation"
        label="民族"
        :rules="[{ required: true, message: '请选择民族', trigger: 'onChange' }]"
        placeholder="请选择民族"
        @click="showNation = true"
      />
      <van-popup v-model:show="showNation" position="bottom">
        <van-picker
          :default-index="0"
          v-model="state.nation"
          :columns="familyName"
          value-key="label"
          @confirm="onNation"
          @cancel="showNation = false"
        />
      </van-popup>

      <van-field
        v-model="state.idCardDeadline"
        readonly
        clickable
        required
        name="calendar"
        label="证件有效期至"
        placeholder="请选择证件有效期"
        :rules="[{ required: true, message: '请选择证件有效期', trigger: 'onChange' }]"
        @click="showIdCardDeadline = true"
      />
      <van-popup v-model:show="showIdCardDeadline" position="bottom">
        <van-datetime-picker
          v-model="idCardDeadline"
          type="date"
          title="请选择证件有效期"
          :max-date="maxDate"
          @confirm="onIdCardDeadline"
          @cancel="showIdCardDeadline = false"
          format="yyyy-MM-dd"
        >
          <template #columns-bottom>
            <div class="longTime" @click="onIdCardDeadline('长期有效')">长期有效</div>
          </template>
        </van-datetime-picker>
      </van-popup>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "@/utils/ui";
import { isCardId, isCardIdInfo } from "@/utils";
import { checkIdCard } from "@/api";

import { parseTime } from "@/utils";

export default {
  props: {
    familyName: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const idCardDeadline = new Date();

    // 身份证为15或者18位
    const validatorIdCard = val => isCardId(val);

    const asyncValidator = val =>
      new Promise(resolve => {
        Toast.loading("验证中...");

        setTimeout(() => {
          Toast.clear();
          resolve(/\d{6}/.test(val));
        }, 1000);
      });
    return {
      idCardDeadline,
      validatorIdCard,
      asyncValidator
    };
  },
  data() {
    return {
      showPicker: true, // 时间选择
      showNation: false, // 民族
      showIdCardDeadline: false, // 日期
      state: {
        idCardType: "身份证",
        idCardCode: 111,
        idCard: "",
        memberName: "",
        sex: 0, // 男0 女1
        birthday: "",
        nation: "汉族",
        nationCode: 1, // 民族code
        idCardDeadline: ""
      }
    };
  },
  computed: {
    maxDate() {
      // 顺延20年年底
      const dd = new Date();
      const year = dd.getFullYear() + 20;
      const newDate = `${year}-12-31`;
      return new Date(newDate);
    }
  },
  mounted() {
    this.getBirth(this.state.idCard);
  },
  methods: {
    formatterSpace(val) {
      // 过滤空格
      return val.replace(/\s+/g, "");
    },
    async onSubmit() {
      let flag = false;
      await this.$refs.filedFrom
        .validate()
        .then(async () => {
          const { code, msg } = await checkIdCard({
            idCard: this.state.idCard,
            memberName: this.state.memberName,
            idCardCode: 111
          });
          if (code !== 200) {
            flag = false;
          } else {
            flag = true;
          }
        })
        .catch(() => {
          flag = false;
        });
      return flag;
    },
    onNation(a) {
      this.showNation = false;
      this.state.nation = a.label;
      this.state.nationCode = a.value;
    },
    onIdCardDeadline(a) {
      this.showIdCardDeadline = false;
      if (a === "长期有效") {
        this.state.idCardDeadline = "长期有效";
        this.idCardDeadline = new Date();
        return;
      }
      this.state.idCardDeadline = parseTime(a, "{y}-{m}-{d}");
    },
    idCardBlur(val) {
      this.getBirth(val.target.value);
    },
    // 获取出生年月日
    getBirth(val) {
      const birthday = isCardIdInfo(val).birthday || "";
      if (birthday) {
        const year = String(birthday).substr(0, 4);
        const month = String(birthday).substr(4, 2);
        const day = String(birthday).substr(6, 2);
        this.state.birthday = `${year}-${month}-${day}`;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.filed-from {
  .tagTitle {
    font-family: "PingFangSC-Medium";
    font-size: 15px;
    color: #969696;
    padding: 8px 12px;
  }
}
.longTime {
  border: 1px solid #ebedf0;
  font-size: 15px;
  padding: 10px 40px;
  font-weight: 600;
}
</style>
