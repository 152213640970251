<template>
  <div class="filed-from">
    <van-form ref="informationFrom" @submit="onSubmit" alidate-first>
      <div class="tagTitle">申请人信息</div>
      <van-field
        v-model="state.halfBodyPhoto"
        name="halfBodyPhoto"
        label="上传照片"
        required
        :rules="rules.halfBodyPhoto"
      >
        <template #input>
          <van-uploader
            v-model="halfBodyPhotoFile"
            accept="image/*"
            multiple
            :max-count="1"
            :after-read="tohalfBodyPhotoFile"
            @delete="onDelete"
          />
          <div class="textBottomTips">一寸照片/免冠/白底彩色</div>
        </template>
      </van-field>
      <van-field
        v-model="state.politicalOutlook"
        readonly
        clickable
        is-link
        name="politicalOutlook"
        label="政治面貌"
        placeholder="请选择政治面貌"
        required
        :rules="[{ required: true, message: '请选择政治面貌', trigger: 'onChange' }]"
        @click="showPoliticalOutlook = true"
      />
      <van-popup v-model:show="showPoliticalOutlook" position="bottom">
        <van-picker
          value-key="label"
          :columns="policitalStatus"
          @confirm="onPoliticalOutlook"
          @cancel="showPoliticalOutlook = false"
        />
      </van-popup>

      <van-field
        v-model="state.provinceName"
        readonly
        clickable
        is-link
        name="provinceName"
        label="常驻省份"
        placeholder="请选择常驻省份"
        required
        :rules="[{ required: true, message: '请选择常驻省份', trigger: 'onChange' }]"
        @click="showProvinceCode = true"
      />
      <van-popup v-model:show="showProvinceCode" position="bottom">
        <van-picker
          value-key="name"
          :columns="provinceList"
          @confirm="onProvinceCode"
          @cancel="showProvinceCode = false"
        />
      </van-popup>

      <van-field
        v-model="state.cityName"
        readonly
        clickable
        is-link
        name="cityName"
        label="常驻市"
        placeholder="请选择常驻市"
        required
        :rules="[{ required: true, message: '请选择常驻市', trigger: 'onChange' }]"
        @click="showCityName = true"
      />
      <van-popup v-model:show="showCityName" position="bottom">
        <van-picker value-key="name" :columns="cityList" @confirm="onCityName" @cancel="showCityName = false" />
      </van-popup>

      <van-field
        v-model="state.addressDetail"
        name="addressDetail"
        label="详细地址"
        required
        maxlength="30"
        placeholder="请输入详细地址"
        :formatter="formatterFirstlastSpaces"
        :rules="[{ required: true, message: '请输入详细地址' }]"
      />

      <van-field
        v-model="state.belongCompany"
        name="belongCompany"
        label="加入公司"
        required
        readonly
        placeholder="请输入加入公司"
        class="belongCompany"
        :rules="[{ required: true, message: '请输入加入公司' }]"
      />

      <van-field
        v-model="state.qualificationsType"
        readonly
        clickable
        is-link
        name="qualificationsType"
        label="最高学历"
        placeholder="请选择最高学历"
        required
        :rules="[{ required: true, message: '请选择最高学历', trigger: 'onChange' }]"
        @click="showQualificationsType = true"
      />
      <van-popup v-model:show="showQualificationsType" position="bottom">
        <van-picker
          value-key="label"
          :columns="educationalDegree"
          @confirm="onQualificationsType"
          @cancel="showQualificationsType = false"
        />
      </van-popup>

      <van-field
        v-model="state.phoneNumber"
        name="phoneNumber"
        label="手机号"
        required
        placeholder="请输入手机号"
        type="digit"
        maxlength="11"
        :formatter="formatterSpace"
        :rules="[
          { required: true, message: '请输入手机号' },
          { validator: validatorPhoneNumber, message: '请输入正确手机号' }
        ]"
      />

      <van-field
        v-model="verificationCode"
        center
        clearable
        name="verificationCode"
        label="短信验证码"
        type="digit"
        maxlength="6"
        required
        :formatter="formatterSpace"
        :placeholder="verificationCodeMessage"
        :rules="[{ required: true, message: verificationCodeMessage }]"
      >
        <template #button>
          <div class="verificationCode" v-if="sendCode" @click="toSendSms">获取验证码</div>
          <div class="verificationCode second" v-else>{{ authTime }}s后重新获取</div>
        </template>
      </van-field>

      <div class="tagTitle">邀请人信息</div>
      <van-field
        class="belongCompany"
        v-model="refereeName"
        name="refereeName"
        label="邀请人姓名"
        readonly
        placeholder=""
      />
      <van-field
        class="belongCompany"
        v-model="refereePhone"
        name="refereePhone"
        label="邀请人手机"
        readonly
        placeholder=""
      />
    </van-form>
    <div class="agreement">
      <van-checkbox class="agreementCheck" icon-size="12" v-model="checked" shape="square" />
      <div class="agreementText">
        我已阅读并同意签署：
        <a href="javascript:;" @click="toMpPreviewPdfPage('/file/o6T3MeZ7q.pdf')">《保险经纪业务委托合同》</a>
      </div>
    </div>
  </div>
</template>

<script>
import { selectRefereeInfoByRefereeId, sendSms, uploadImageByJpg, getAllArea } from "@/api";
import { Toast } from "@/utils/ui";
import { toMpPreviewPdfPage } from "@/utils";

export default {
  props: {
    educationalDegree: {
      type: Array,
      require: true,
      default: () => []
    },
    policitalStatus: {
      type: Array,
      require: true,
      default: () => []
    },
    refereeId: {
      type: String,
      require: true,
      default: ""
    }
  },
  setup() {
    const idCardDeadline = new Date();
    const validatorPhoneNumber = val => /^1(3|4|5|6|7|8|9)\d{9}$/.test(val);
    return {
      idCardDeadline,
      validatorPhoneNumber
    };
  },
  data() {
    return {
      halfBodyPhotoFile: [],
      showPoliticalOutlook: false, // 政治面貌
      showQualificationsType: false, // 学历
      showProvinceCode: false, // 常驻省份
      showCityName: false, // 常驻市区
      showPicker: true, // 时间选择
      showNation: false, // 民族
      showIdCardDeadline: false, // 日期
      provinceList: [], // 省
      cityList: [], // 市
      politicalOutlookCode: 0,
      sendCode: true,
      authTime: 60, // 倒计时时间
      verificationCode: "", // 验证码
      verificationCodeMessage: "请输入短信验证码", //重新输入验证码
      refereeName: "",
      refereePhone: "",
      state: {
        refereeId: "",
        halfBodyPhoto: "",
        politicalOutlook: "", // 政治面貌
        politicalOutlookCode: "",
        provinceName: "", // "常驻省份",
        provinceCode: "", // "省份code",
        cityName: "", // "常驻市",
        cityCode: "", // "市code",
        addressDetail: "", // 详细地址
        belongCompany: "深圳市红彤诚新保险经纪有限公司",
        qualificationsType: "", // 最高学历
        qualificationsCode: "",
        phoneNumber: ""
      },
      checked: false,
      rules: {
        halfBodyPhoto: [{ required: true, message: "请上传一寸照片/免冠/白底彩色" }]
      }
    };
  },
  watch: {
    "state.halfBodyPhoto"(newVal) {
      this.halfBodyPhotoFile = this.setImgType(newVal);
    }
  },
  mounted() {
    this.selectRefereeInfoByRefereeId();
    this.getAllArea();
  },

  methods: {
    toMpPreviewPdfPage,
    onDelete() {
      this.state.halfBodyPhoto = "";
      this.rules.halfBodyPhoto[0].message = "请上传一寸照片/免冠/白底彩色";
    },
    formatterSpace(val) {
      // 过滤空格
      return val.replace(/\s+/g, "");
    },
    // 去除左侧空格
    formatterFirstlastSpaces(val) {
      // console.log(9999,val.replace(/(^\s+)|(\s+$)/g,""))
      return val.replace(/(^\s+)/g, "");
    },
    setImgType(url) {
      let imgList = [];
      if (url) {
        imgList = [{ url }];
      }
      return imgList;
    },
    async onSubmit() {
      let flag = false;
      if (this.sendCode === true) {
        Toast("请获取验证码");
      } else {
        await this.$refs.informationFrom
          .validate()
          .then(() => {
            if (this.checked === false) {
              Toast("请勾选协议");
            } else {
              flag = true;
            }
          })
          .catch(() => {
            flag = false;
          });
      }
      return flag;
    },
    // 获取经纪人信息
    async selectRefereeInfoByRefereeId() {
      const res = await selectRefereeInfoByRefereeId({ refereeId: this.refereeId }); // eg:978
      const { refereeName, refereePhone } = res.data;
      this.state.refereeId = this.refereeId;
      this.refereeName = refereeName;
      this.refereePhone = refereePhone;
    },
    // 上传申请人照片
    async tohalfBodyPhotoFile(file) {
      const imgDetail = file.file || {};
      // if(imgDetail.size > 1024 * 200) {
      //   this.state.halfBodyPhoto = ''
      //   this.halfBodyPhotoFile = []
      //   this.rules.halfBodyPhoto[0].message = '请上传小于200KB的照片'
      //   this.$refs.informationFrom.validate('halfBodyPhoto')
      //   return
      // }
      const formData = new FormData();
      formData.append("img", imgDetail);
      const {
        code,
        data: { filename }
      } = await uploadImageByJpg(formData);
      if (code !== 200) {
        this.halfBodyPhotoFile = [];
        Toast("图片上传失败");
        return;
      }
      this.state.halfBodyPhoto = filename;
    },
    async getAllArea() {
      const { data } = await getAllArea();
      this.provinceList = data || [];
    },
    toSendSms() {
      this.$refs.informationFrom
        .validate("phoneNumber")
        .then(async () => {
          const { code } = await sendSms({
            // 发送验证码
            templateCodeType: 1,
            userPhone: this.state.phoneNumber,
            channelSource: 8
          });
          if (code !== 200) {
            return;
          }
          this.sendCode = false;
          this.authTime = 60;
          const timeInt = setInterval(() => {
            this.authTime--;
            if (this.authTime <= 0) {
              this.sendCode = true;
              window.clearInterval(timeInt);
            }
          }, 1000);
        })
        .catch(() => {
          Toast("请先输入手机号");
          return;
        });
    },
    // 政治面貌
    onPoliticalOutlook(a) {
      this.showPoliticalOutlook = false;
      this.state.politicalOutlook = a.label;
      this.state.politicalOutlookCode = a.value;
    },
    // 最高学历
    onQualificationsType(a) {
      this.showQualificationsType = false;
      this.state.qualificationsType = a.label;
      this.state.qualificationsCode = a.value;
    },
    // 省份
    onProvinceCode(a) {
      this.state.cityName = "";
      this.state.cityCode = "";
      this.showProvinceCode = false;
      this.state.provinceName = a.name;
      this.state.provinceCode = a.code;
      const cityList = this.provinceList.filter(item => {
        if (item.code === a.code) {
          return item;
        }
      });
      this.cityList = cityList[0].childrenList || [];
    },
    onCityName(a) {
      this.showCityName = false;
      this.state.cityName = a.name;
      this.state.cityCode = a.code;
    },
    onNation(a) {
      this.showNation = false;
      this.state.nation = a;
    },
    onIdCardDeadline(a) {
      this.showIdCardDeadline = false;
      this.state.idCardDeadline = a;
    }
  }
};
</script>

<style lang="less" scoped>
.filed-from {
  .tagTitle {
    font-family: "PingFangSC-Medium";
    font-size: 15px;
    color: #969696;
    padding: 8px 12px;
  }
  .textBottomTips {
    font-family: "PingFangSC-Regular";
    font-size: 12px;
    color: #cdcdcd;
    background: #fff;
    width: 100%;
  }
  /deep/ .van-field__control--custom {
    flex-direction: column;
    align-items: flex-start;
  }
}
.agreement {
  position: relative;
  .agreementCheck {
    border: 1px solid #d4d4d4;
    border-radius: 2px;
    position: absolute;
    top: 15px;
    left: 12px;
  }
  .agreementText {
    font-family: "PingFangSC-Regular";
    font-size: 12px;
    color: #969696;
    line-height: 18px;
    padding: 12px 12px 20px;
    text-indent: 1.5em;
    width: 100%;
    box-sizing: border-box;
  }
  a {
    color: #0079f5;
  }
  a:visited {
    color: #0079f5;
  }
}
.verificationCode {
  width: 105px;
  font-family: "PingFangSC-Regular";
  font-size: 13px;
  color: #00b955;
}
.second {
  color: #989898 !important;
}
.belongCompany {
  /deep/ .van-field__control {
    color: #747474;
  }
}
</style>
