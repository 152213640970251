<template>
  <div>
    <div class="main-steps">
      <img src="https://files.htprudent.com/G2/M00/DA/9B/CiICX2BdcjyAOuACAAA9k4Ob44w685.png" alt="" />
    </div>
    <div class="main-remind">
      依照行业监管要求，为了保障您的利益，请准确填写以下实名信息。我们庄重承诺以下信息仅用于您申请保险执业证，且严格保密。
    </div>

    <!--身份信息-->
    <filed-from ref="filedFrom" v-show="stepNext === 2" v-model:family-name="familyName" />

    <!--上传/回显 身份证-->
    <filed-id ref="filedId" v-model:idz="state.idCardPositiveImg" v-model:idf="state.idCardReverseSideImg" />
  </div>
</template>

<script>
import filedId from "@/views/authentication/filedId";
import filedFrom from "@/views/authentication/filedFrom";
import { selectDictItemByTypes } from "@/api";

export default {
  components: {
    filedId,
    filedFrom
  },
  props: {
    stepNext: {
      type: [Number],
      required: true,
      default: 1
    }
    // idz: {
    //   type: String,
    //   required: true,
    //   default: ''
    // },
    // idf: {
    //   type: String,
    //   required: true,
    //   default: ''
    // }
  },

  data() {
    return {
      state: {
        idCardPositiveImg: "",
        idCardReverseSideImg: ""
      },
      familyName: [], // 民族
      educationalDegree: [], // 学历
      policitalStatus: [], // 成分
      idFrom: {
        value1: ""
      },
      step: true
    };
  },
  async created() {
    this.selectDictItemByTypes();
  },
  mounted() {},

  methods: {
    // 字典
    async selectDictItemByTypes() {
      const {
        data: {
          educational_degree, // 学历
          family_name, // 民族
          policital_status // 成分
        }
      } = await selectDictItemByTypes(["family_name", "educational_degree", "policital_status"]);
      this.educationalDegree = educational_degree;
      this.familyName = family_name;
      this.policitalStatus = policital_status;
    }
  }
};
</script>

<style lang="less" scoped>
.main-steps {
  height: 88px;
  img {
    height: 100%;
  }
}
.main-remind {
  background: url("https://files.htprudent.com/G2/M00/DA/9C/CiICX2BdcluANf9CAAADM6CHmOc108.png") no-repeat #fbf6f0;
  background-size: 12px 14px;
  background-position: 12px 12px;
  font-family: "PingFangSC-Regular";
  font-size: 12px;
  color: #ff6e12;
  padding: 10px 20px 10px 30px;
  line-height: 18px;
}
</style>
