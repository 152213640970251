<template>
  <div class="join-train">
    <div class="join-train__title">
      <span>请完成以下答题</span>
      <span
        ><strong>{{ topicNO }}</strong
        >/5</span
      >
    </div>
    <div class="join-train__main">
      <div class="main_title">
        {{ joinTrainNo.title }}
      </div>
      <span v-for="(item, index) in joinTrainNo.describe" :key="index">{{ item }}</span>
      <van-radio-group class="choice" v-model="answer" icon-size="16" checked-color="#00B955" @change="answerChange">
        <van-radio
          style="margin-top: 10px"
          v-for="(item, index) in joinTrainNo.choice"
          :key="index"
          :name="index + 1"
          >{{ item }}</van-radio
        >
      </van-radio-group>
      <div class="ans-error" v-if="ansError">回答错误，请重新选择</div>
    </div>
  </div>
</template>

<script>
import { joinTrain } from "@/views/authentication/joinTrain.js";
export default {
  data() {
    return {
      topicNO: 1,
      answer: "",
      ansError: false
    };
  },
  computed: {
    joinTrainNo() {
      return joinTrain[this.topicNO - 1];
    }
  },
  created() {},
  mounted() {},
  methods: {
    answerChange(val) {
      this.ansError = false;
      if (this.answer !== "") {
        if (this.joinTrainNo.answer === val) {
          if (this.topicNO === 5) {
            this.$emit("submit", true);
            return;
          }
          this.topicNO = this.topicNO + 1;
          this.answer = "";
        } else {
          this.ansError = true;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.join-train {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    font-family: "PingFangSC-Regular";
    font-size: 14px;
    color: #969696;
    padding: 10px 16px;
    display: flex;
    justify-content: space-between;
    b {
      color: #00b955;
    }
  }
}
.join-train__main {
  flex: 1;
  background: red;
  padding: 10px 16px;
  background: #fff;
  color: #333;
  .main_title {
    font-family: "PingFangSC-Medium";
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  span {
    font-family: "PingFangSC-Regular";
    font-size: 14px;
    display: block;
    line-height: 24px;
    // margin-top: 10px;
  }
  .choice {
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  /deep/ .van-radio__label {
    font-family: "PingFangSC-Regular";
    font-size: 14px;
    display: block;
    line-height: 24px;
  }
  /deep/ .van-radio {
    align-items: baseline;
  }
  .ans-error {
    font-family: "PingFangSC-Medium";
    font-size: 16px;
    color: #ff5050;
    margin-top: 12px;
  }
}
</style>
