<template>
  <base-layout>
    <div class="authentication">
      <!--内容部分-->
      <div class="authentication-main">
        <!--身份证上传-->
        <upload-documents
          ref="uploadId"
          v-show="stepNext === 1 || stepNext === 2"
          v-model:step-next="stepNext"
          v-model:idz="idsz"
          v-model:idf="idsf"
        />
        <!--填写相关信息-->
        <information
          ref="information"
          v-show="stepNext === 3"
          :step-next="stepNext"
          v-model:educational-degree="educationalDegree"
          v-model:policital-status="policitalStatus"
          v-model:referee-id="refereeId"
        />
        <!--岗前培训-->
        <join-train v-if="stepNext === 4" @submit="commitRefereeInfo" />
      </div>

      <!--底部-->
      <div class="authentication-bottom">
        <img
          v-if="stepNext === 1"
          src="https://files.htprudent.com/G1/M00/DB/44/CiIDJ2BdchiAGB6QAACUnaubw5Q973.png"
          alt=""
        />
        <div style="padding: 12px">
          <van-button
            v-if="stepNext === 1"
            class="IDSummit"
            :disabled="btnDisabled"
            type="primary"
            @click="IDSubmit(2)"
            :color="colorBtn"
            >上传</van-button
          >
          <van-button
            v-if="stepNext === 2"
            class="IDSummit"
            :disabled="btnDisabled"
            type="primary"
            @click="IDSubmit(3)"
            :color="colorBtn"
            >下一步</van-button
          >
          <van-button
            v-if="stepNext === 3"
            class="IDSummit"
            :disabled="btnDisabled"
            type="primary"
            @click="IDSubmit(4)"
            :color="colorBtn"
            >下一步</van-button
          >
        </div>
      </div>
    </div>

    <!--申请须知-->
    <van-action-sheet v-model:show="applicationGuide">
      <div class="applicationGuide">
        <div class="guide-title">申请须知</div>
        <div class="guide-content">
          <span>本人声明：</span>
          <span>1.未因牵涉刑事罪行或违法而被逮捕、指控或判刑；</span>
          <span>2.未因牵涉任何商业行为规范或合规行为而被行政处罚；</span>
          <span>3.未曾在过往的公司获得过违纪违规等处分</span>
          <span>本人已知晓上述要求，确认如实申请</span>
        </div>
        <van-button type="primary" block :color="colorBtn" @click="onApplicationGuide">确定</van-button>
      </div>
    </van-action-sheet>
  </base-layout>
</template>

<script>
import uploadDocuments from "@/views/authentication/uploadDocuments";
import information from "@/views/authentication/information";
import joinTrain from "@/views/authentication/joinTrain1";
import { Toast } from "@/utils/ui";
import { toMpAuthResultPage } from "@/utils";
import { commitRefereeInfo, checkValidCode, selectDictItemByTypes, selectMemberInfo } from "@/api";

export default {
  components: {
    uploadDocuments,
    information,
    joinTrain
  },
  data() {
    return {
      btnDisabled: false,
      idsz: "",
      idsf: "",
      colorBtn: "#00B955",
      stepNext: 1,
      applicationGuide: false,
      educationalDegree: [],
      policitalStatus: []
    };
  },
  created() {
    document.title = "身份验证";
    if (this.$route.query.status === "false") {
      this.selectMemberInfo();
    }
    this.refereeId = this.$route.query.refereeId || "";
    this.selectDictItemByTypes();
  },

  methods: {
    // 回显详细
    async selectMemberInfo() {
      this.btnDisabled = true;
      const { code = 1, data = {} } = await selectMemberInfo();
      if (code !== 200) {
        // Toast('暂时不能重新验证')
        return;
      }

      // 回显赋值
      const filedFrom = this.$refs.uploadId.$refs.filedFrom.state;
      const filedId = this.$refs.uploadId.state;
      const information_ = this.$refs.information.state;
      if (Object.keys(data).length > 0) {
        Object.keys(filedFrom).forEach((key) => {
          if (data[key]) {
            filedFrom[key] = data[key];
          }
        });
        Object.keys(filedId).forEach((key) => {
          if (data[key]) {
            filedId[key] = data[key];
          }
        });
        Object.keys(information_).forEach((key) => {
          if (data[key]) {
            information_[key] = data[key];
          }
        });
        this.btnDisabled = false;
      }
    },
    // 字典
    async selectDictItemByTypes() {
      const {
        data: {
          educational_degree, // 学历
          // family_name,   // 民族
          policital_status // 成分
        }
      } = await selectDictItemByTypes(["family_name", "educational_degree", "policital_status"]);
      this.educationalDegree = educational_degree || [];
      // this.familyName = family_name
      this.policitalStatus = policital_status || [];
    },
    async IDSubmit(type) {
      if (type === 2 || type === 3) {
        if (!this.idCard()) {
          return;
        }
      }

      if (type === 3) {
        document.title = "填写相关信息";
        const flagFrom = await this.$refs.uploadId.$refs.filedFrom.onSubmit();
        if (flagFrom === false) {
          return;
        }
      }

      if (type === 4) {
        const informationFrom = await this.$refs.information.onSubmit();
        if (informationFrom === false) {
          return;
        }
        this.checkValidCode(); // 验证手机验证码
        return;
      }

      this.stepNext = type;
    },
    // 身份证验证
    idCard() {
      const flag = true;
      const uploadId_data = this.$refs.uploadId.state;
      if (uploadId_data.idCardPositiveImg === "") {
        Toast("请上传身份证正面照片");
        return false;
      }
      if (uploadId_data.idCardReverseSideImg === "") {
        Toast("请上传身份证反面照片");
        return false;
      }
      return flag;
    },
    // 申请须知
    onApplicationGuide() {
      this.applicationGuide = false;
      this.stepNext = 4;
      document.title = "岗前培训";
    },
    // 提交
    async commitRefereeInfo(flag) {
      const filedFrom = this.$refs.uploadId.$refs.filedFrom.state;
      const filedId = this.$refs.uploadId.state;
      const information_ = this.$refs.information.state;
      const parms = {
        ...filedFrom,
        ...filedId,
        ...information_
      };
      const { code } = await commitRefereeInfo(parms);
      //Toast(msg)
      if (code === 200) {
        // this.$router.push({
        //   path: 'auth-result'
        // })
        toMpAuthResultPage();
      }
    },
    // 验证手机验证码
    async checkValidCode() {
      const phoneNumber = this.$refs.information.state.phoneNumber;
      const phoneNumberCode = this.$refs.information.verificationCode;
      const { code, msg } = await checkValidCode({
        userPhone: phoneNumber,
        validCode: phoneNumberCode
      });
      if (code !== 200) {
        Toast.fail(msg);
        this.$refs.information.verificationCode = "";
        this.$refs.information.verificationCodeMessage = "请重新输入验证码";
        await this.$refs.information.onSubmit();
        return;
      }
      this.applicationGuide = true;
    }
  }
};
</script>

<style lang="less" scoped>
.authentication {
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .authentication-main {
    overflow: auto;
    flex: 1;
    background: #f5f7f8;
  }
  .authentication-bottom {
    background: #fff;
    img {
      width: 100%;
    }
    .IDSummit {
      width: 100%;
      font-family: "PingFangSC-Medium";
      font-size: 16px;
      color: #ffffff;
      padding: 12px;
    }
  }
}
.applicationGuide {
  padding: 20px 12px;
  .guide-title {
    font-family: "PingFangSC-Semibold";
    font-size: 18px;
    color: #353535;
    text-align: center;
  }
  .guide-content {
    margin-top: 16px;
    font-family: "PingFangSC-Medium";
    font-size: 15px;
    color: #555555;
    line-height: 21px;
    span {
      display: block;
    }
    span:first-child {
      color: #353535;
    }
    span:last-child {
      font-family: "PingFangSC-Regular";
      color: #ff6e12;
      margin: 9px 0 30px;
    }
  }
}
</style>
