<template>
  <base-layout>
    <div class="addPanel">
      <img class="p-banner" src="https://files.htprudent.com/G1/M00/E1/65/CiICH2Bhh3KACUELAAlTxmoVfgQ330.png" alt="" />
      <div class="addPanel-join">
        <img
          class="p-join-btn"
          ref="box"
          src="https://files.htprudent.com/G2/M00/E0/AF/CiICX2BhiBKAIZnOAADf0cEBQUg403.png"
          alt=""
          @click="onJoin"
        />
      </div>
      <img class="p-banner" src="https://files.htprudent.com/G1/M00/E1/84/CiIDJ2BhioGAaCoQAAE-0NS3qag588.png" alt="" />
      <img class="p-banner" src="https://files.htprudent.com/G2/M00/EE/10/CiIDVmBmeSeACTztAAIWmTyggic679.png" alt="" />
      <img class="p-banner" src="https://files.htprudent.com/G1/M00/E1/74/CiICH2BhivyAIBEtAAJRP4_CNIg323.png" alt="" />
      <img class="p-banner" src="https://files.htprudent.com/G2/M00/E0/CB/CiIDVmBhjfWAQwDgAAEkYI99Cgs578.png" alt="" />
      <van-sticky :offset-bottom="0" position="bottom" v-if="footerJoinBtn">
        <div class="footerJoinBtn">
          <img
            src="https://files.htprudent.com/G2/M00/E0/AF/CiICX2BhiBKAIZnOAADf0cEBQUg403.png"
            alt=""
            @click="onJoin"
          />
        </div>
      </van-sticky>
    </div>
  </base-layout>
</template>

<script>
import qs from "qs";
import { login, getToken } from "@/utils";

export default {
  components: {},

  data() {
    return {
      isLogin: false,
      refereeId: null,
      status: true, // 认证状态
      footerJoinBtn: false
    };
  },
  created() {
    document.title = "一键加入荷保保";
  },
  mounted() {
    let { token, status = true, refereeId } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (!token) {
      token = getToken();
    }
    this.isLogin = Boolean(token);
    this.status = status;
    this.refereeId = refereeId;
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      if (this.$refs.box.getBoundingClientRect().top <= -50) {
        this.footerJoinBtn = true;
      } else {
        this.footerJoinBtn = false;
      }
    },
    onJoin() {
      if (!this.isLogin) {
        login();
        return;
      }

      this.$router.push({
        path: "authentication",
        query: {
          status: this.status,
          refereeId: this.refereeId
        }
      });
    }
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll, true);
  }
};
</script>

<style lang="less" scoped>
.addPanel {
  width: 100%;
  height: 100%;
  height: 100vh;
  background: #5fdb77;
  .p-banner {
    width: 100%;
    display: block;
  }
  &-join {
    width: 100%;
    overflow: hidden;
    background: url("https://files.htprudent.com/G2/M00/E0/AC/CiICX2Bhh8KAIjguAAC0CqP8eoM242.png") no-repeat;
    background-size: 100% auto;
    height: 316px;
    text-align: center;
    .p-join-btn {
      margin-top: 212px;
      height: 59px;
    }
  }
  .footerJoinBtn {
    background: #5fdb77;
    padding: 10px 14px 7px;
    text-align: center;
    img {
      display: block;
      height: 65px;
      margin: 0 auto;
    }
  }
}
</style>
