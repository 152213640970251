<template>
  <div class="Id-filed">
    <van-uploader
      class="singleImg"
      preview-size="100%"
      v-model="filedZ"
      multiple
      :max-count="1"
      accept="image/*"
      :after-read="ImgSubmit"
      @delete="onDelete(1)"
    >
      <img src="https://files.htprudent.com/G3/M00/DB/3B/CiIDZmBdcdCAAzk4AAB8QEGl5YM659.png" alt="" />
    </van-uploader>

    <van-uploader
      class="singleImg"
      preview-size="100%"
      v-model="filedF"
      multiple
      :max-count="1"
      accept="image/*"
      :after-read="ImgSubmit2"
      @delete="onDelete(2)"
      style="margin-top: 20px"
    >
      <img src="https://files.htprudent.com/G3/M00/DB/3B/CiIDZmBdceaAO0OrAACDHsr8xX0830.png" alt="" />
    </van-uploader>
  </div>
</template>

<script>
import { uploadImage } from "@/api/index";
import { Toast } from "@/utils/ui";

export default {
  props: {
    idz: {
      type: String,
      default: ""
    },
    idf: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      filedZ: [],
      filedF: []
    };
  },
  watch: {
    idz(newVal) {
      this.filedZ = this.setImgType(newVal);
    },
    idf(newVal) {
      this.filedF = this.setImgType(newVal);
    }
  },
  methods: {
    setImgType(url) {
      let imgList = [];
      if (url) {
        imgList = [{ url }];
      }
      return imgList;
    },
    onDelete(a) {
      if (a === 1) {
        this.$emit("update:idz", "");
      } else {
        this.$emit("update:idf", "");
      }
    },
    // 图片上传服务器
    async ImgSubmit(file) {
      const formData = new FormData();
      formData.append("img", file.file);
      const {
        code,
        data: { filename }
      } = await uploadImage(formData);
      if (code !== 200) {
        this.$emit("update:idz", "");
        this.filedZ = [];
        Toast("图片上传失败");
        return;
      }
      this.$emit("update:idz", filename);
    },
    async ImgSubmit2(file) {
      const formData = new FormData();
      formData.append("img", file.file);
      const {
        code,
        data: { filename }
      } = await uploadImage(formData);
      if (code !== 200) {
        this.$emit("update:idf", "");
        this.filedF = [];
        Toast("图片上传失败");
        return;
      }
      this.$emit("update:idf", filename);
    },
    // 图片上传服务器
    async imgSubmit(file) {
      const formData = new FormData();
      formData.append("img", file.file);
      const {
        data: { filename }
      } = await uploadImage(formData);
      return filename;
    }
  }
};
</script>

<style lang="less" scoped>
.Id-filed {
  width: 280px;
  margin: 30px auto;
  /deep/ .van-uploader__preview {
    margin: 0;
    width: 100%;
  }
  /deep/ .van-uploader__wrapper {
    height: 100%;
  }
  .singleImg {
    width: 100%;
    height: 180px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
