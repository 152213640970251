export const joinTrain = [
  {
    title: "1.下列不是保险经纪人的特点是（）", // 标题
    describe: [], // 标题1
    choice: [
      // 选择
      "A 保险经纪人作为被保险人的代表，独立承担法律责任",
      "B 保险经纪人提供的服务专业性强",
      "C 保险经纪人业务贯穿保险经营的始终",
      "D 如果因为保险经纪机构的疏忽致使被保险人的利益受到损害，保险经纪机构要承担法律"
    ],
    answer: 3 // 答案
  },
  {
    title:
      "2.勤勉尽责是对保险经纪从业人员工作态度的基本要求，它要求保险经纪从业人员在业务活动中以客户利益为上，勤奋工作、尽职尽责。下列选项中对于保险经纪从业人员勤勉尽责要求表述错误的是（）", // 标题
    describe: [], // 标题1
    choice: [
      // 选择
      "A 秉持勤勉的工作态度，努力避免执业活动中的失误",
      "B 代表客户利益，对于客户的各项委托尽职尽责，确保客户的利益得到最好的保障，且不因手续费（佣金）或服务的高低影响客户利益",
      "C 忠诚服务，不侵害所属机构利益，切实履行对所属机构的责任和义务，接受所属机构的管理",
      "D 超越客户的委托范围或所属机构的授权"
    ],
    answer: 4 // 答案
  },
  {
    title:
      "3.我国保险经纪行业还处于发展的初级阶段，人们对于保险经纪人的市场作用还没有充分认识，对于保险经纪人还有种种疑虑和不解。作为保险经纪人，应该让客户充分认识到其能够给（）带来的实际利益，使其认识到保险经纪人在风险管理方面的优势和在解决保险市场信息不对称问题的作用。", // 标题
    describe: [], // 标题1
    choice: [
      // 选择
      "A 投保人和收益人",
      "B 受益人和被保险人",
      "C 投保人和被保险人",
      "D 投保人和保险"
    ],
    answer: 3 // 答案
  },
  {
    title: "4.保险业对残疾判定使用统一的标准是由( )制定的", // 标题
    describe: [], // 标题1
    choice: [
      // 选择
      "A 保险公司和保监会",
      "B 保险协会和法医协会",
      "C 医院和法医协会",
      "D 保监"
    ],
    answer: 2 // 答案
  },
  {
    title:
      "5.保险专业代理机构及其从业人员在开展保险代理业务过程中，不得有以下欺骗投保人；被保险人；受益人或者保险公司的行为", // 标题
    describe: [
      "(1) 误导性销售",
      "(2) 伪造，擅自变更保险合同，销售假保险单证，或者为保险合同当事人提供虚假证明材料",
      "(3) 阻碍投保人履行如实告知义务或者诱导其不履行如实告知义务",
      "(4) 隐瞒或者与保险合同有关的重要情况"
    ], // 标题1
    choice: [
      // 选择
      "A (1) (2)",
      "B (1) (2) (3)",
      "C (1) (2) (3) (4)"
    ],
    answer: 3 // 答案
  }
];
